export default function Results({ products }) {
  return (
    <ul className="mx-auto max-w-7xl py-14 px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
      {products.map((product) => (
        <li key={product.source} className="relative w-3/5 mx-auto sm:w-full sm:mx-0" >
          <div className="">
            {/* <img
              alt={product.purchase_description}
              src="https://naghedi.s3.amazonaws.com/2025Spring/STBARTHS_SOLID_IPANEMA_LARGE_0039.jpg"
              className="pointer-events-none object-cover object-[50%_75%] w-full h-full"
            /> */}
          </div>
          <div className="space-y-1">
          <div className="mt-2 block text-sm font-medium text-gray-900">
            {product.product_collection_group.split(product.fabrication)[0]}
          </div>
          <div className="text-sm text-gray-700">
            <span>{product.fabrication}</span>{product.fabrication_variation ? <span>&nbsp; ({product.fabrication_variation})</span> : ""}
          </div>
            <div className="flex justify-between">
              <span className="text-sm text-gray-500">
                Original Style No.
              </span>
              <span className="text-sm text-gray-700">
                {product.legacy_style_number}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-gray-500">
                Current Style No.
              </span>
              <span className="text-sm text-gray-700">
                {product.style_number}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

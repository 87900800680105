import { useEffect, useState } from "react";
import "./App.css";
import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import Navigation from "./components/Navigation";
import Results from "./components/Results";
import Login from "./components/Login";

const firebaseConfig = {
  apiKey: "AIzaSyDz_Q8SEMI0iJEWduBIwtwXGR1kZIdxGfo",
  authDomain: "naghedi-api.firebaseapp.com",
  projectId: "naghedi-api",
  storageBucket: "naghedi-api.appspot.com",
  messagingSenderId: "528236837471",
  appId: "1:528236837471:web:3717f4f4c539e6509239ea",
};

const fb_app = initializeApp(firebaseConfig);
const auth = getAuth(fb_app);

function App() {
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [error, setError] = useState(""); // State for error messages

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setError(""); // Clear error on successful login
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setError(""); // Clear previous error message
    try {
      await setPersistence(auth, browserSessionPersistence);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("Signed in:", user);
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setError("Incorrect password. Please try again.");
          break;
        case "auth/user-not-found":
          setError("No user found with this email. Please sign up.");
          break;
        default:
          setError("Error signing in. Please try again.");
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Signed out");
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  return (
    <div className="App">
      {user && <Navigation handleSignOut={handleSignOut} setProducts={setProducts} user={user} />}

      {user ? (
        <Results products={products} />
      ) : (
        <Login
          email={email}
          error={error}
          handleSignIn={handleSignIn}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      )}
    </div>
  );
}

export default App;
